@import '../common/color';

.fanthon-inputmask-checkout {
  > .ant-typography {
    text-align: left;
    display: flex;
    text-indent: 0;
  }
}

.fanthon-inputmask {
  > .ant-typography {
    text-align: left;
    display: flex;
    // text-indent: 10px;
  }
  .ant-input-affix-wrapper {
    border: 1px solid @black-200;
    background: @white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 0px @shadow;
    border-radius: 6px;

    &.ant-input-affix-wrapper-focused,
    &:hover {
      border: 1px solid @primary;
      box-shadow: 0px 2px 0px @shadow;
      border: 1px solid @primary;

      .anticon {
        color: @black-300;
      }
    }

    .anticon {
      color: @black-700;
    }
  }
}

.required-star {
  color: #ff4d4f;
  display: inline-block;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  width: 10px;
}
